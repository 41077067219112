<div class="temp-body">
    <div class="news-act-template">
        <div class="temp-1">
            <div class="temp-1-title">
                <textarea class="txt-title none-select" [(ngModel)]="title" placeholder="write title here"></textarea>
            </div>
            <div class="temp-1-des">
                <textarea class="txt-des none-select" [(ngModel)]="des" placeholder="write description here"></textarea>
            </div>
            <div class="temp-1-img1" (click)="openTemp1_img('temp1_img01','open_temp1_img01')">
                <img height="100%" id="temp1_img01">
                <input type="file" id="open_temp1_img01" hidden>
            </div>
            <div class="temp-1-img2" (click)="openTemp1_img('temp1_img02','open_temp1_img02')">
                <img width="100%" id="temp1_img02">
                <input type="file" id="open_temp1_img02" hidden>
            </div>
            <div class="temp-1-img3" (click)="openTemp1_img('temp1_img03','open_temp1_img03')">
                <img height="100%" id="temp1_img03">
                <input type="file" id="open_temp1_img03" hidden>
            </div>
            <div class="temp-1-img4" (click)="openTemp1_img('temp1_img04','open_temp1_img04')">
                <img width="100%" id="temp1_img04">
                <input type="file" id="open_temp1_img04" hidden>
            </div>
        </div>
        <div class="temp-2"></div>
        <div class="temp-3"></div>
    </div>
    <div class="loading"><img src="assets/svg/loading.svg" [hidden]="!loading"></div>
    <div class="btn-group">
        <button class="temp-btn" (click)="saveClick()">save</button>
        <button class="temp-btn" (click)="onNoClick()">close</button>
    </div>
</div>
