<div class="a_n_body" style="margin-bottom:130px;">
    <div class="a_n_header">
        <div class="header-text"><div class="inner-lb"> <u>NEWS & ACTIVITIES</u> </div></div>
        <img class="header-img" src="assets/svg/header_bar.svg" width="1300">
    </div>
    <div class="news-act-body">
        <div class="news-act-item" *ngIf="!news_act_emp">
            <div *ngFor="let item of news_act" class="news-act-temp temp{{item.temp}}">
                <div class="boonhome temp-title">{{item.title}}</div>
                <div class="boonhome temp-des">{{item.des}}</div>
                <div class="temp-img01">
                    <img src="{{item.img01_path}}" width="100%">
                </div>
                <div class="temp-img02">
                    <img src="{{item.img02_path}}" width="100%">
                </div>
                <div class="temp-img03">
                    <img src="{{item.img03_path}}" height="100%">
                </div>
                <div class="temp-img04">
                    <img src="{{item.img04_path}}" width="100%">
                </div>
            </div>
        </div>
        <div class="empty-news-act" *ngIf="news_act_emp"></div>
    </div>
</div>