<section id=des-ody style="margin-bottom:130px;">
    <div class="header">OUDOMXAY</div>
    <div class="body">
        <b>Oudomxay:</b> The town of Muang La is home to the important Saymoung Rattana Stupa which houses 
        a 400-year-old Buddha image. Adventurers will want to explore Chom Ong Cave, it is the longest and largest 
        known cave in Laos at around 18.4km long.
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>
    <div class="map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d986143.9980513!2d101.17693899493582!3d20.635984678487578!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x312926328c4ac9ad%3A0xc26d3d8f930ca89a!2z4LuB4LqC4Lqn4LqH4Lqt4Lq44LqU4Lq74Lqh4LuE4LqK!5e0!3m2!1slo!2sla!4v1586697576775!5m2!1slo!2sla"
            width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
            tabindex="0"></iframe>
    </div>
</section>