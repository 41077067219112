<section id=des-lpb style="margin-bottom:130px;">
    <div class="header">PHONGSALY</div>
    <div class="body">
        <b>Phongsaly:</b> Phongsaly Province covers an area of 16,270 square kilometres (6,280 sq mi), 
        out of which 77% has forest cover. The province borders China to the north and west, Vietnam to the east, 
        Luang Prabang Province to the south, and Oudomxai Province to the southwest. 
        The highest mountain in the province is Phou Doychy with an elevation of 1,842 metres (6,043 ft) Protected areas 
        in the province include the Phou Dene Din National Biodiversity Conservation Area and Nam Lan Conservation Area. 
        Agriculture is the mainstay of the people of the province. Phongsaly is the primary trade gateway between Laos and China, 
        exporting lumber and importing several types of finished goods.
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>
    <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d37543.38198846669!2d102.08882688945177!3d21.679293067399776!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x312c9dd04c5f1c57%3A0x59087782176b77b5!2sPh%C3%B4ngsali!5e0!3m2!1slo!2sla!4v1626230550150!5m2!1slo!2sla" 
            width="100%" height="400" style="border:0;" allowfullscreen="" loading="lazy">
        </iframe>
    </div>
</section>