<section id=des-zby style="margin-bottom:130px;">
    <div class="header">SAYABOULY PROVINCE</div>
    <div class="body">
        <b>Savannakhet Province: </b>is situated in the middle of the country and is the largest province (21,774 square kilometers), bordered by Vietnam to the East, Thailand to the West, Khammouane Province to the North and Salavanh Province to the South. 

        The name of Savannakhet Province derives from old Pali language, meaning “a land of gold”. The area of Savannakhet Province has been inhabited since ancient times, benefitting from the Mekong River and its tributaries. People here have cultivated rice in the largest plain in the country, and fostered rich arts and culture.
        
        60% of land is covered by forests. A wide variety of ethnic groups are found in the province: Lowland Lao, Phoutai, Thai Dam, Katang, Mangkong, Vali, Lava, Soui, Kapo, Kaleung and Ta-Oi. In addition, many Vietnamese and Chinese descendants live in the province as well. This fact makes the province rich in cultural and gastronomic diversity.
        
        Savannakhet has long been a centre of trade and power. The province is well noted as the birthplace of the Honorable Kaisone Phomvihan, first Secretary of the Lao People’s Revolutionary Party and co-founder of the Lao PDR. His house and a museum documenting his life achievements can be visited in Savannakhet Town.
        
        The province’s history, however, goes back long before the time of man. The area was once inhabited by dinosaurs as evidenced by the bones found in Bang Tang Wai Village and the giant footprints found in Phalanxay, which are documented in the Savannakhet Dinosaur Museum. Remnants of an ancient civilization can be seen at the Stone temple, Heuan Hin, a pre-Angkorian building located 40km downstream from town along the Mekong. That Ing Hang Stupa, originally built more than 1.000 years ago and later rebuilt by King Saysethathirath in the 16th century, is the province’s most important religious site. Also of interest is the historic library, Hortay Pidok, which is believed to have been built in the 18th century and was traditionally used to house old “Bai Lan” Buddhist scriptures.
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>

    <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3935589.21347852!2d105.0346658!3d15.5729999!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313dc60688062c13%3A0xff13318c5ce5dc74!2sSavannakhet%20Province!5e0!3m2!1sen!2sla!4v1717728851069!5m2!1sen!2sla" 
        width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
        </iframe>
    </div>
</section>