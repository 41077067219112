import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-laoskyway',
  templateUrl: './laoskyway.component.html',
  styleUrls: ['./laoskyway.component.scss']
})
export class LaoskywayComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
