<section id=des-parkse style="margin-bottom:130px;">
    <div class="header">CHAMPASAK(PAKSE)</div>
    <div class="body">
        <b>Champasack: </b>lies to the Southwest and is one of the main political and economic centers of Lao PDR. The capital city, Pakse, is located at the confluence of the Mekong and the Sedon Rivers. 

        In this province you will find ancient temples which were influenced by the Angkor people who settled in Cambodia. There are many different minorities in Champasack who have their own language, culture and lifestyles. 
        
        Champasack Province is known for its relaxed pace of life, warm hospitality and rich cultural, historic and natural heritage. The province has been ruled by various kingdoms through the ages, and today there are many archaeological remains scattered throughout the province. To the south of Pakse is the Wat Phou Temple Complex, a UNESCO World Heritage Site. Attractions also include the Ancient City, historic colonial buildings, and Done Daeng Island, known for its traditional livelihoods and forested trails. In the southern region of the province is Done Khong and the Four Thousand Islands, or Si Phan Done in Lao. On this stretch of the Mekong is the largest waterfall by volume in Southeast Asia, Khone Phapheng, as well as Li Phi waterfall, noted for its cascading emerald green waters. The endangered freshwater Irrawaddy Dolphins inhabit the Mekong near the Lao-Cambodian border and can be observed from locally chartered boats..
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>

    <div class="map">
        <iframe 
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30844.42900093369!2d105.84850772425047!3d14.906218056483988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3114f471b6bf1d17%3A0xacfb24744c2ae433!2sMuang%20Champassak!5e0!3m2!1sen!2sla!4v1672727400518!5m2!1sen!2sla" 
        width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
        </iframe>
    </div>
</section>