import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NewsActivitiesDialogComponent } from 'src/app/dialogs/news-activities-dialog/news-activities-dialog.component';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import baseData from '../../../assets/json/data.json';

@Component({
  selector: 'app-admin-news-activities',
  templateUrl: './admin-news-activities.component.html',
  styleUrls: ['./admin-news-activities.component.scss']
})
export class AdminNewsActivitiesComponent implements OnInit {

  @Output() loading = new EventEmitter<boolean>();
  constructor(public dialog: MatDialog,private http:HttpClient) {}

  news_act_data:{
    id: number;
    title: string;
    des: string;
    temp: string;
    img01_path: string;
    img02_path: string;
    img03_path: string;
    img04_path: string;
    img01: string;
    img02: string;
    img03: string;
    img04: string;
    user: string;
    last_update: Date
  }

  ngOnInit(): void {
    this.load_data();
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(NewsActivitiesDialogComponent, {
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
      if(result){
        this.load_data();
      }
    });
  }
  load_data(){
    this.loading.emit(true);
    this.http.get<any>(baseData.baseURL+'/get_news_activities').subscribe(result=>{
      if(result.res == "success"){
        this.news_act_data = result.data;
      }else{
        this.loading.emit(false);
        // window.alert("can not load data");
      }
    }, (err: HttpErrorResponse) => {
      this.loading.emit(false);
      // window.alert("can not load data, error code: " + err.status + " " + err.statusText);
      //finished
    }, () => {
      this.loading.emit(false);
      //finished
    });
  }
  delete_data(id:number, img01: string, img02: string, img03: string, img04: string){
    if (confirm("are you sure, you want to delete this News & Activities")) {
      this.loading.emit(true);
      let data = {
        "id":id,
        "img01":img01,
        "img02":img02,
        "img03":img03,
        "img04":img04
      }
      this.http.post<any>(baseData.baseURL + '/delete_news_act',data).subscribe(result=>{

      },(err:HttpErrorResponse)=>{
        this.loading.emit(false);
        window.alert("can not delete!, error code: "+err.status+" "+err.statusText);
      },()=>{
        this.loading.emit(false);
        window.alert("success !");
        this.load_data();
      });
    }
  }

}
