<div class="header">
  <label *ngIf="!data.edit">Add New User</label>
  <label *ngIf="data.edit">Edit User</label>
</div>
<div *ngIf="data.edit" style="text-align: center;"><label>user id : USID00{{data.id}}</label></div>

<form [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="form-group">
    <div class="label">
      <div class="label-text">Name</div>
      <div class="label-error" *ngIf="submitted && f.name.errors">
        <span *ngIf="f.name.errors.required">Name is required</span>
      </div>
    </div>
    <input type="text" formControlName="name" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
  </div>
  <div class="form-group">
    <div class="label">
      <div class="label-text">User Group</div>
      <div class="label-error" *ngIf="submitted && f.user_group.errors">
        <span *ngIf="f.user_group.errors.required">User Group is required</span>
      </div>
    </div>
    <select formControlName="user_group" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.user_group.errors }">
      <option value="administrator">administrator</option>
      <option value="marketing">marketing</option>
    </select>
  </div>
  <div class="form-group">
    <div class="label">
      <div class="label-text">Username</div>
      <div class="label-error" *ngIf="submitted && f.username.errors">
        <span *ngIf="f.username.errors.required">Username is required</span>
      </div>
    </div>
    <input type="text" formControlName="username" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
  </div>
  <div class="form-group">
    <div class="label">
      <div class="label-text">Password</div>
      <div class="label-error" *ngIf="submitted && f.password.errors">
        <span *ngIf="f.password.errors.required">Password is required</span>
      </div>
    </div>
    <input type="password" formControlName="password" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
  </div>
  <div class="form-group">
    <div class="label">
      <div class="label-text">Confirm Password</div>
      <div class="label-error" *ngIf="submitted && f.confirmPassword.errors">
        <span *ngIf="f.confirmPassword.errors.required">Confirm Password is required</span>
        <span *ngIf="f.confirmPassword.errors.mustMatch">Password must match</span>
      </div>
    </div>
    <input type="password" formControlName="confirmPassword" class="form-control"
      [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
  </div>
  <div class="form-group">
    <div class="form-check">
      <input type="checkbox" class="form-check-input" id="Active" formControlName="status">
      <label class="form-check-label" for="Active">Active</label>
    </div>
  </div>
  <div class="text-center">
    <button type="submit" class="btn btn-primary" [innerHTML]="loading"></button> 
    <button class="btn btn-danger" (click)="cancel()">Cancel</button>
  </div>
</form>