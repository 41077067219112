import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BossViewComponent } from 'src/app/dialogs/boss-view/boss-view.component';

@Component({
  selector: 'app-org-chart',
  templateUrl: './org-chart.component.html',
  styleUrls: ['./org-chart.component.scss']
})
export class OrgChartComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }
  view_boss(img,name){
    const dialogRef = this.dialog.open(BossViewComponent,{
      data:{
        img:img,
        name:name
      }
    });
  }

}
