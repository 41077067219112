<section id=des-zby style="margin-bottom:130px;">
    <div class="header">SAYABOULY PROVINCE</div>
    <div class="body">
        <b>Sayabouly Province: </b>is situated in the northwest of Laos, sharing borders with Vientiane Province and Luang Prabang Province in the east, and Thailand in the west. This mountainous province has several peaks with altitudes of more than 1,000 meters. Travelling to this rugged landscape will reveal many beautiful mountains and flower gardens. The local people earn their living via agricultural products such as growing rice, cucumbers, cotton, cabbage, beans and sugarcane. Elephants continue to be used for loading and transporting heavy items. The province is also an important agricultural producer of rice, cotton, peanuts, sesame, maize and oranges.
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>

    <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1934978.6643404986!2d100.02907908501581!3d18.697389220933058!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3126332ca8e76217%3A0xa4664e894beb3b75!2sSainyabuli%20Province!5e0!3m2!1sen!2sla!4v1672732472372!5m2!1sen!2sla" 
        width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
    </iframe>
    </div>
</section>