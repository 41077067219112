import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-charter-filght',
  templateUrl: './charter-filght.component.html',
  styleUrls: ['./charter-filght.component.scss']
})
export class CharterFilghtComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
