import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import baseData from '../../../assets/json/data.json';

@Component({
  selector: 'app-news-activities-dialog',
  templateUrl: './news-activities-dialog.component.html',
  styleUrls: ['./news-activities-dialog.component.scss']
})
export class NewsActivitiesDialogComponent implements OnInit {
  img01:File = null;
  img02:File = null;
  img03:File = null;
  img04:File = null;
  title:string="";
  des:string="";
  loading = false;
  constructor(public dialogRef:MatDialogRef<NewsActivitiesDialogComponent>,private http: HttpClient) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialogRef.close(false);
  }
  openTemp1_img(img_id:string,img_file:string) {
    const fileUpload = document.getElementById(img_file) as HTMLInputElement;
    const img = document.getElementById(img_id) as HTMLInputElement;
 
    fileUpload.click();
    fileUpload.onchange = () => {

      
      if(img_id=='temp1_img01'){
        if (fileUpload.files[0] != null){
          if(fileUpload.files[0].size<=1500000){
            this.img01 = fileUpload.files[0];
            img.src = URL.createObjectURL(fileUpload.files[0]);
          }else{
            alert('please use file size less than 1.5 MB')
          }
        }
      }else if(img_id=='temp1_img02'){
        if (fileUpload.files[0] != null){
          if(fileUpload.files[0].size<=1500000){
            this.img02 = fileUpload.files[0];
            img.src = URL.createObjectURL(fileUpload.files[0]);
          }else{
            alert('please use file size less than 1.5 MB')
          }
        }
      }else if(img_id=='temp1_img03'){
        if (fileUpload.files[0] != null){
          if(fileUpload.files[0].size<=1500000){
            this.img03 = fileUpload.files[0];
            img.src = URL.createObjectURL(fileUpload.files[0]);
          }else{
            alert('please use file size less than 1.5 MB')
          }
        }
      }else if(img_id=='temp1_img04'){
        if (fileUpload.files[0] != null){
          if(fileUpload.files[0].size<=1500000){
            this.img04 = fileUpload.files[0];
            img.src = URL.createObjectURL(fileUpload.files[0]);
          }else{
            alert('please use file size less than 1.5 MB')
          }
        }
      }

    };
  }
  saveClick(){
    if(this.des!=""&&this.title!=""&&this.img01!=null&&this.img02!=null&&this.img03!=null&&this.img04!=null){
      this.loading = true;
      let img01_name = "";
      let img02_name = "";
      let img03_name = "";
      let img04_name = "";
      //upload img01
      this.upload_img(this.img01).subscribe(img01 => {
        img01_name = img01.img_name;
        //upload img02
        this.upload_img(this.img02).subscribe(img02 => {
          img02_name = img02.img_name;
          //upload img03
          this.upload_img(this.img03).subscribe(img03 => {
            img03_name = img03.img_name;
            //upload img04
            this.upload_img(this.img04).subscribe(img04 => {
              img04_name = img04.img_name;
              let data = {
                'title':this.title,
                'des':this.des,
                'temp':1,
                'img01':img01_name,
                'img02':img02_name,
                'img03':img03_name,
                'img04':img04_name,
                'update_user': sessionStorage.getItem("user_name")
              }
              this.http.post<any>(baseData.baseURL + '/save_news_activities', data).subscribe(save_result => {

              }, (save_err: HttpErrorResponse) => {
                window.alert("can not save!, error code:" + save_err.status + " " + save_err.statusText);
                this.loading = false;
                //finished
              }, () => {
                window.alert("success !");
                this.dialogRef.close(true);
                this.loading = false;
                //finished
              });
            }, (err: HttpErrorResponse) => {
              window.alert("can not save!, error code:" + err.status + " " + err.statusText);
              this.loading = false;
            });
          }, (err: HttpErrorResponse) => {
            window.alert("can not save!, error code:" + err.status + " " + err.statusText);
            this.loading = false;
          });
        }, (err: HttpErrorResponse) => {
          window.alert("can not save!, error code:" + err.status + " " + err.statusText);
          this.loading = false;
        });
      }, (err: HttpErrorResponse) => {
        window.alert("can not save!, error code:" + err.status + " " + err.statusText);
        this.loading = false;
      });
    }
  }
  upload_img(file: File): any {
    const formData = new FormData();
    formData.append('file', file);
    return this.http.post<any>(baseData.baseURL + '/upload_img_file', formData);
  }
}
