import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-on-construction',
  templateUrl: './on-construction.component.html',
  styleUrls: ['./on-construction.component.scss']
})
export class OnConstructionComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
