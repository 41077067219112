<section id=des-vte style="margin-bottom:130px;">
    <div class="header">VIENTIANE CAPITAL</div>
    <div class="body">
        <b>Vientiane Capital: </b>is the home to the most significant national monument in Laos: That Luang (Great Stupa), which is the symbol of Lao and an icon of Buddhism in Laos. Of the many beautiful Wats in Vientiane, a visit to Wat Sisaket is a must; built in 1818, this is one of the oldest temples in Vientiane. Other Buddhist holy places are Wat Ong Teu Mahavihan, known for its 16th century bronze Buddha sheltered by a carved wooden masterpiece, and Wat Si Muang, the site of the Lak Meuang or pillar-stone of Vientiane. Wat Si Meuang is also home to the guardian spirit of the city. Hor Phakeo, across the street from Wat Sisaket houses a beautiful collection of Buddha statues, including traditional Lao style of the “Calling for Rain” and “Offering Protection”. Spend a morning in the Lao National Museum, which displays an interesting mixture of revolutionary and contemporary exhibitions. The main sights in Vientiane are only a short walk or bicycle ride from most hotels. Wat Xieng Khouan, better known as the Buddha Park should not be missed: take a tuk-tuk to this unique park that includes Buddhist and Hindu. Shopping for handicrafts is easy in Vientiane Capital; visit Talat Sao (morning market) for a wide range of colourful textiles including silks, wall-hangings and other decorative pieces. For very fine handicrafts, try one of the many upscale galleries in the city center. Keep your eyes open for traditional wood carvings, mulberry paper and a variety of basketry made from bamboo and rattan.
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>

    <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d970756.1402738951!2d101.42087567804487!3d18.11704434733724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31245bd418f46f69%3A0xe88e189467b26642!2sVientiane%20Prefecture!5e0!3m2!1sen!2sla!4v1672732581922!5m2!1sen!2sla" 
        width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
        </iframe>
    </div>
</section>