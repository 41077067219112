<div class="footer">
    <div class="social">
        <a href="#">
            <img src="assets/footer_ico/call_icon.png" width="60">
        </a>
        <p style="margin-top: 5px; font-size: 12px;">Tel: (+856-21) 512027</p>
    </div>
    <div class="social">
        <a href="#">
            <img src="assets/footer_ico/fax_icon.png" width="60">
        </a>
        <p style="margin-top: 5px; font-size: 12px;">Fax: (+856-21) 512027</p>
    </div>
    <div class="social">
        <a href="mailto:info@laoskyway.com">
            <img src="assets/footer_ico/email_icon.png" width="60">
        </a>
        <p style="margin-top: 5px; font-size: 12px;">info@laoskyway.com</p>
    </div>
    <div class="social">
        <a href="https://www.facebook.com/laoskyway" target="_blank">
            <img src="assets/footer_ico/facebook_icon.png" width="60">
        </a>
        <p style="margin-top: 5px; font-size: 12px;">Facebook</p>
    </div>
    <div class="social">
        <a href="https://www.youtube.com/@laoskyway4180" target="_blank">
            <img src="assets/footer_ico/youtube_icon.png" width="60">
        </a>
        <p style="margin-top: 5px; font-size: 12px;">Youtube</p>
    </div>
    
    <div class="social">
        <a href="/feedback">
            <img src="assets/footer_ico/feed_icon.png" width="60">
        </a>
        <p style="margin-top: 5px; font-size: 12px;">Feedback</p>
    </div>
    <p style=" font-size: 12px; color: rgb(255, 255, 255);"><br>© Copyright LaoSkyway. All Rights Reserved </p>
</div>

