import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import baseData from '../../../assets/json/data.json';
@Component({
  selector: 'app-activities',
  templateUrl: './activities.component.html',
  styleUrls: ['./activities.component.scss']
})
export class ActivitiesComponent implements OnInit {

  news_act:{
    id: string;
    title: string;
    des: string;
    temp: string;
    img01_path: string;
    img02_path: string;
    img03_path: string;
    img04_path: string;
    img01: string;
    img02: string;
    img03: string;
    img04: string;
    user: string;
    last_update: string;
  }
  news_act_emp = true;
  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.load_data();
  }
  load_data() {
    this.http.get<any>(baseData.baseURL + '/get_news_activities').subscribe(result => {
      if (result.res == "success") {
        // this.slide_list = result.data;
        this.news_act = result.data;
        // console.log(this.slide_data);
        // this.slide_num =  Object.keys(this.slide_data).length;
        this.news_act_emp = false;
      } else {
        // this.slide_num = 0;
        this.news_act_emp = true;
        // window.alert("can not load data, error: " + result.detail);
        //finished
      }
    }, (err: HttpErrorResponse) => {
      // window.alert("can not load data, error code: " + err.status + " " + err.statusText);
      //finished
    }, () => {
      //finished
    });
  }
}
