<section id=route-map style="margin-bottom:130px;">
    <div class="body">
        <div class="contact-head cell">
            <div>
                Contact
            </div>
        </div>
        <div class="vte-contact cell">
            <div class="vte-head item">
                <div>ຫ້ອງການສາຂານະຄອນຫຼວງວຽງຈັນ</div>
                <div>Laoskyway ticketing & reservation office</div>
            </div>
            <div class="lsw-hotel item">
                <div class="item-head">
                    <div>ຫ້ອງຂາຍປີ້ໂຮງແຮມລາວສະກາຍເວ</div>
                    <div>Laoskyway Hotel</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)21 513 249,
                        (856)30
                        5181 488
                    </div>
                </div>
            </div>
            <div class="vte-airport item">
                <div class="item-head">
                    <div>ຫ້ອງຂາຍປີ້ສະໜາມບິນສາກົນວັດໄຕ</div>
                    <div>VTE Domestic Airport</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)21 513 007,
                        (856)20
                        5663 2854
                    </div>
                </div>

            </div>
        </div>
        <div class="agent-contact cell">
            <div class="agent-head item">
                <div>ຫ້ອງການສາຂາຕ່າງແຂວງ</div>
                <div>Laoskyway Agent ticketing & reservation office</div>
            </div>
            <div class="HOE item">
                <div class="item-head">
                    <div>ສາຂາແຂວງຫົວພັນ (HOUAPHANH)</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/fax.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)64 314 268
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 9975
                        5556,
                        5555 4537
                    </div>
                </div>
            </div>

            <div class="PCQ item">
                <div class="item-head">
                    <div>ສາຂາແຂວງຜົ້ງສາລີ (PHONGSALY)</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/fax.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)88 210 111
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 5551
                        4858,
                        5555 6688
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 2221
                        1580,
                        5558 8258
                    </div>
                </div>
            </div>

            <div class="BOE item">
                <div class="item-head">
                    <div>ສາຂາແຂວງບໍ່ແກ້ວ (BOKEO)</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/fax.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)84 211 764
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 2874
                        3343,
                        9998 8600
                    </div>
                </div>
            </div>

            <div class="LGX item">
                <div class="item-head">
                    <div>ສາຂາແຂວງຫຼວງນໍ້າທາ (LUANGNAMTHA)</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/fax.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)86 312 141,
                        212 075
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 5414
                        0099,
                        5677 5523
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 2239
                        2054
                    </div>
                </div>
            </div>

            <div class="ODY item">
                <div class="item-head">
                    <div>ສາຂາແຂວງອຸດົມໄຊ (ODOMXAY)</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/fax.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)81 312 577
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 2873
                        3006,
                        2312 2219
                    </div>
                </div>
            </div>

            <div class="ODY item">
                <div class="item-head">
                    <div>ສາຂາແຂວງຫຼວງພະບາງ (LUANGPRABANG)</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/fax.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)71 254 750
                    </div>
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)20 2298
                        8809,
                        5688 3400
                    </div>
                </div>
            </div>

            <div class="ODY item">
                <div class="item-head">
                    <div>ສາຂາແຂວງຊຽງຂວາງ (XIENGKHOUANG)</div>
                </div>
                <div class="item-body">
                    <div style="padding-top: 6px;">
                        <img src="/assets/svg/phone.svg" style="width: 20px; margin-top: -6px;">&nbsp;(856)30 944
                        0053
                    </div>
                </div>
            </div>
        </div>
        <div class="map cell">
            <iframe src="/assets/route_map/index.html" frameborder="0" scrolling="no" class="route-map"></iframe>
        </div>

    </div>
</section>