<div class="main-popup" (click)="popup_closed()">
    <div class="popup-image">
        <div class="close-popup one">
            <mat-icon style="font-size: 30px;" (click)="popup_closed()">cancel</mat-icon>
        </div>
        <a href="/assets/imgs/notify01.jpg" target="_blank">
            <img class="img" src="/assets/imgs/notify01.jpg"></a>
    </div>
    <!-- <div class="popup-image">
        <div class="close-popup two">
            <mat-icon style="font-size: 30px;" (click)="popup_closed()">cancel</mat-icon>
        </div>
        <a href="/assets/imgs/17799.jpg" target="_blank">
            <img class="img" src="/assets/imgs/17799.jpg"></a>
    </div> -->
</div>