<section id=des-bokeo style="margin-bottom:130px;">
    <div class="header">BOKEO</div>
    <div class="body">
        <b>Bokeo:</b> Home to the Bokeo Nature Reserve, a protected forest area created to preserve the
        black crested gibbon. Elephants, bears and tigers have also been spotted here and it houses The Gibbon
        Experience – a popular eco-tourism activity where participants traverse the forest by zip-line.
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>
    <div class="map">
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1047833.1298552186!2d100.17616140070616!3d20.36630857652458!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3128320af2bfb607%3A0xb72079c271e9e8f2!2z4LuB4LqC4Lqn4LqH4Lqa4LuN4LuI4LuB4LqB4LuJ4Lqn!5e0!3m2!1slo!2sla!4v1586673423825!5m2!1slo!2sla"
            width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false"
            tabindex="0"></iframe>
    </div>
</section>