<section id=org-chart style="margin-bottom:130px;">
    <div class="org company">
        <a href="/assets/org_img/BOD_Group.jpeg" target="_blank">
            <img src="/assets/org_img/BOD_Group.jpeg">
        </a>
    </div>
    <div class="org company">
        <a href="/assets/org_img/ORG_Group.png" target="_blank">
            <img src="/assets/org_img/ORG_Group.png">
        </a>
    </div>
</section>