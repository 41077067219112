import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { OnConstructionComponent } from './components/on-construction/on-construction.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { WebpageAdminComponent } from './components/webpage-admin/webpage-admin.component';
import { LaoskywayComponent } from './components/laoskyway/laoskyway.component';
import { FleetInfoComponent } from './components/fleet-info/fleet-info.component';
import { CargoTrackingComponent } from './components/cargo-tracking/cargo-tracking.component';
import { RouteMapComponent } from './components/route-map/route-map.component';
import { OrgChartComponent } from './components/org-chart/org-chart.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { LuangprabangDesComponent } from './components/luangprabang-des/luangprabang-des.component';
import { BokeoDesComponent } from './components/bokeo-des/bokeo-des.component';
import { OudomxayDesComponent } from './components/oudomxay-des/oudomxay-des.component';
import { LuangnamthaDesComponent } from './components/luangnamtha-des/luangnamtha-des.component';
import { FlightScheduleComponent } from './components/flight-schedule/flight-schedule.component';
import { AirlineComponent } from './components/airline/airline.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { CharterFilghtComponent } from './components/charter-filght/charter-filght.component';
import { AdminNewsActivitiesComponent } from './components/admin-news-activities/admin-news-activities.component';
import { CareerComponent } from './components/career/career.component';
import { PhongsalyDesComponent } from './components/phongsaly-des/phongsaly-des.component';
import { ParkseDesComponent } from './components/parkse-des/parkse-des.component';
import { NueDesComponent } from './components/nue-des/nue-des.component';
import { VteDesComponent } from './components/vte-des/vte-des.component';
import { ZbyDesComponent } from './components/zby-des/zby-des.component';
import { ZvkDesComponent } from './components/zvk-des/zvk-des.component';
import { TicketCheckingComponent } from './components/ticket-checking/ticket-checking.component';
import { PrivateJetComponent } from './components/private-jet/private-jet.component';
import { CargoInfoComponent } from './components/cargo-info/cargo-info.component';


const routes: Routes = [
  {path:'',component:HomeComponent},
  {path:'on-construction',component:OnConstructionComponent},
  {path:'feedback',component:FeedbackComponent},
  {path:'wp-admin',component:WebpageAdminComponent},
  {path:'about-us-laoskyway',component:LaoskywayComponent},
  {path:'about-us-FleetInfo',component:FleetInfoComponent},
  {path:'cargo_check',component:CargoTrackingComponent},
  {path:'route_map',component:RouteMapComponent},
  {path:'org_chart',component:OrgChartComponent},
  {path:'contact_us',component:ContactUsComponent},
  {path:'luangprabang_des',component:LuangprabangDesComponent},
  {path:'bokeo_des',component:BokeoDesComponent},
  {path:'oudomxay_des',component:OudomxayDesComponent},
  {path:'phongsaly_des',component:PhongsalyDesComponent},
  {path:'parkse_des',component:ParkseDesComponent},
  {path:'luangnamtha_des',component:LuangnamthaDesComponent},
  {path:'flight_schedule',component:FlightScheduleComponent},
  {path: 'airline', component:AirlineComponent},
  {path: 'activities', component:ActivitiesComponent},
  {path: 'terms&conditions',component:TermsConditionsComponent},
  {path: 'charter_filght',component:CharterFilghtComponent},
  {path: 'news&activities',component:AdminNewsActivitiesComponent},
  {path: 'careers', component:CareerComponent},
  {path: 'nue_des', component:NueDesComponent},
  {path: 'vte_des', component:VteDesComponent},
  {path: 'zby_des', component:ZbyDesComponent},
  {path: 'zvk_des', component:ZvkDesComponent},
  {path: 'ticket-checking', component:TicketCheckingComponent},
  {path: 'private-jet', component:PrivateJetComponent},
  {path: 'cargo-info', component:CargoInfoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
