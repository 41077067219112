<section id=fleetInfo style="margin-bottom:130px;">
    <div class="item ma60">
        <div class="cell header">
            <div class="title">MA-60</div>
            <div class="title-line"></div>
        </div>
        <div class="cell img">
            <img src="/assets/airplane/ma60.png">
        </div>
        <div class="cell info">
            <div class="fleet-item fleet-item--1"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Taxi Weight: 21,900 Kg</div>
            <div class="fleet-item fleet-item--2"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Take off Weight: 21,800 Kg</div>
            <div class="fleet-item fleet-item--3"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Zero Fuel Empty Weight: 19,500 Kg</div>
            <div class="fleet-item fleet-item--4"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Speed: 430 Km/h</div>
            <div class="fleet-item fleet-item--5"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Range: 2,580 Km</div>
            <div class="fleet-item fleet-item--6"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Crew: 2</div>
            <div class="fleet-item fleet-item--7"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Seat Capacity: 56</div>
        </div>
    </div>
    <div class="item cessana">
        <div class="cell header">
            <div class="title">CESSANA 208 GRAND CARAVAN</div>
            <div class="title-line"></div>
        </div>
        <div class="cell img">
            <img src="/assets/airplane/cessana.png">
        </div>
        <div class="cell info">
            <div class="fleet-item fleet-item--2"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Take off Weight: 4,110 Kg</div>
            <div class="fleet-item fleet-item--3"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Zero Fuel Empty Weight: 2,445 Kg</div>
            <div class="fleet-item fleet-item--4"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Speed: 280 Km/h</div>
            <div class="fleet-item fleet-item--5"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Range: 1,440 Km</div>
            <div class="fleet-item fleet-item--6"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Crew: 2</div>
            <div class="fleet-item fleet-item--7"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Seat Capacity: 11</div>
        </div>
    </div>
    <div class="item mi17">
        <div class="cell header">
            <div class="title">MI-17V1</div>
            <div class="title-line"></div>
        </div>
        <div class="cell img">
            <img src="/assets/airplane/mi17.png">
        </div>
        <div class="cell info">
            <div class="fleet-item fleet-item--1"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Taxi Weight: 11,000 Kg</div>
            <div class="fleet-item fleet-item--2"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Take off Weight: 13,000 Kg</div>
            <div class="fleet-item fleet-item--3"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Zero Fuel Empty Weight: 7,085 Kg</div>
            <div class="fleet-item fleet-item--4"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Speed: 210 Km/h</div>
            <div class="fleet-item fleet-item--5"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Range: 525 Km</div>
            <div class="fleet-item fleet-item--6"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Crew: 4</div>
            <div class="fleet-item fleet-item--7"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Seat Capacity: 22</div>
        </div>
    </div>
    <div class="item as350" style="padding-bottom: 30px;">
        <div class="cell header">
            <div class="title">AS 350 B2</div>
            <div class="title-line"></div>
        </div>
        <div class="cell img">
            <img src="/assets/airplane/as350.png">
        </div>
        <div class="cell info">
            <div class="fleet-item fleet-item--2"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Take off Weight: 2,250 Kg</div>
            <div class="fleet-item fleet-item--3"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Zero Fuel Empty Weight: 1,314 Kg</div>
            <div class="fleet-item fleet-item--4"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Speed: 200 Km/h</div>
            <div class="fleet-item fleet-item--5"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Range: 462 Km</div>
            <div class="fleet-item fleet-item--6"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Crew: 1-2</div>
            <div class="fleet-item fleet-item--7"><img src="/assets/svg/send.svg" style="width: 20px; margin:0 10px 0 20px;">
                Seat Capacity: 5</div>
        </div>
    </div>
</section>