import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from 'src/app/services/event-emitter.service';
import language from '../../../assets/json/language.json';

@Component({
  selector: 'app-career',
  templateUrl: './career.component.html',
  styleUrls: ['./career.component.scss']
})
export class CareerComponent implements OnInit {

  language_data:any;
  language_:string;
  constructor(private changeLanguage:EventEmitterService) { }

  ngOnInit(): void {
    if(sessionStorage.getItem('language')==undefined || sessionStorage.getItem('language')=='eng'){
      this.language_data = language.en;
      this.language_ = "en";
    }else{
      this.language_data = language.la;
      this.language_ = "la";
    }
    if (this.changeLanguage.subsVar==undefined) {    
      this.changeLanguage.subsVar = this.changeLanguage.    
      invokeChangelanguage.subscribe((data:string) => {    
        this.language_change(data);    
      });    
    }
  }
  language_change(data:string){
    if(data=="eng"){
      this.language_data = language.en;
      this.language_ = "en";
    }else{
      this.language_data = language.la;
      this.language_ = "la";
    }
  }

}
