<div class="career-body" style="margin-bottom:130px;">
    <div class="title-text" [ngClass]="{lao: language_=='la'}">{{language_data.career_title}}</div>
    <div class="career-ico">
        <img src="/assets/svg/career.svg">
    </div>
    <div class="career-content">
        <div class="career-item">
            <div class="item-title lao">ປະກາດຮັບສະໝັກພະນັກງານ</div>
            <div class="create-date">
                <img src="/assets/svg/calendar.svg" width="13"><span> 08/07/2024 - 19/07/2024</span> 
            </div>
            <div class="career-detail lao"><a href="/assets/files/2024-07-16-careers.jpeg" target="_blank">ປະກາດຮັບພະນັກງານຈຳນວນ 1 ຕຳແໜ່ງ (ຄິກເບີ່ງລາຍລະອຽດ)</a></div>
            <div class="btn-view">
                <!--<a class="view-detail" href="/assets/files/doc20230210.pdf" [ngClass]="{lao: language_=='la'}" target="_blank">{{language_data.more_detail}}</a>-->
                <a class="view-detail" href="/assets/files/form_20230815.pdf" [ngClass]="{lao: language_=='la'}" target="_blank">&nbsp;&nbsp; Click Download Form &nbsp;&nbsp;</a>
            </div>
        </div>
    </div>
</div>
