<section id=timeline style="margin-bottom:130px;">
    <h1>Laoskyway</h1>
    <p class="leader">Possible...you can fly</p>
    <div class="demo-card-wrapper">
        <div class="demo-card demo-card--step1">
            <div class="head">
                <h2>History</h2>
            </div>
            <div class="body">
                <p>Lao Skyway is formerly known as Lao Air, it was established on 24 th  January 2002
                    and officially launched services on June 2003 for helicopter charter service, and in
                    the same year on December 2003 Company started to provide fixed wing aircraft service.</p>
                <div class="content-body content-box1">
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">
                        <B>On January 2007:</B> Lao Skyway was the first aviation company in Lao PDR to
                        receive the ISO 9001 version 2000 certification.
                    </div>

                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p2">
                        <B>On April 2007:</B> Lao Skyway launched an airline service for the domestic market.
                    </div>

                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p3">
                        <B>On November 2013:</B> the company English name changed from LAO AIR to
                        LAO SKYWAY in order to differentiate and support the expansion of company
                        service.
                    </div>

                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p4">
                        <B>On April 2014:</B> Lao skyway  utilized larger passenger aircraft for serving the
                        domestic airline market.
                    </div>
                    <div class="p5">
                        Lao skyway provides an airline and charter flight service with the highest
                        standard of safety and excellent customer service with reasonable prices.
                    </div>
                </div>
            </div>
        </div>

        <div class="demo-card demo-card--step2">
            <div class="head">
                <h2>Our domestic destinations:</h2>
            </div>
            <div class="body">
                <div class="content-body content-box2">
                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">LuangPrabang</div>

                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">Luangnamtha</div>

                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">Oudomxay</div>

                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">Bokeo</div>

                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">Phongsaly</div>

                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">Huaphan</div>

                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">Xiengkhouang</div>

                    <div class="des">Vientiane</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">Pakse</div>

                    <div class="des">Xiengkhouang</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="avl">LuangPrabang</div>
                </div>
            </div>
        </div>

        <div class="demo-card demo-card--step3">
            <div class="head">
                <h2>Planning:</h2>
            </div>
            <div class="body">
                <p>we are planning to expand our operations with more domestic
                    destinations and we are also currently studying for international routes
                    expansion.</p>
            </div>
        </div>

        <div class="demo-card demo-card--step4">
            <div class="head">
                <h2>Our charter flight service</h2>
            </div>
            <div class="body">
                <p>Our charter flight service also provides a variety of domestic and international, 
                    non-schedule and schedule flights including:</p>
                <div class="content-body content-box1">
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">Passenger transport</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">Cargo transport</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">Medical evacuation service (domestic and neighboring countries)</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">Aerial survey</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">Scenic flight</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">Aerial photography and other services</div>
                    <div class="ico"><img src="/assets/svg/send.svg" width="20"></div>
                    <div class="p1">Wedding transfer</div>
                </div>

            </div>
        </div>

        <div class="demo-card demo-card--step5">
            <div class="head">
                <h2>Company logo</h2>
            </div>
            <div class="body">
                <p>Our company logo is defined as a bird, which forms the shape of 3 boomerangs.
                    This is representing our aircraft will fly to their destinations and always safely return
                    to home base. Lao Skyway holds safety at the heart of the operations. Our aircraft
                    fully complies with the standards of International Aviation Safety. We adopt a zero
                    tolerance to unsafe practices and strive for zero accidents through constant training
                    programs, work practices, risk management and adherence to safety regulations at
                    all times.</p>
            </div>
        </div>

    </div>
</section>