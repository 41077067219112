<div class="news-activities-section">
    <button class="btn-new-slide" (click)="openDialog()">
        <mat-icon style="float: left;">add</mat-icon>
        <div style="float: left;padding-left: 3px; padding-top: 3px; padding-bottom: 5px; font-size: 18px;">Add</div>
    </button>
    <div class="news-act item-section">
        <div class="news-act-item" *ngFor="let item of news_act_data">
            <div class="content-text">
                <div class="content-title boonhome">(TEMPLATE TYPE: {{item.temp}} ) {{item.title}}</div>
                <div class="create-person">create by: {{item.user}} ( {{item.last_update | date:'dd MMM yyyy hh:mm a'}} )</div>
                <div class="content-des boonhome">{{item.des}}</div>
            </div>
            <div class="img">
                <img src="{{item.img01_path}}" height="70">
                <img src="{{item.img02_path}}" height="70">
                <img src="{{item.img03_path}}" height="70">
                <img src="{{item.img04_path}}" height="70">
            </div>
            <div class="action-btn">
                <button class="none-select" (click)="delete_data(item.id,item.img01,item.img02,item.img03,item.img04)">
                    <div><mat-icon class="ico del">delete</mat-icon></div>
                    <div class="boonhome">ລຶບ</div>
                </button>
            </div>
        </div>
    </div>
</div>
