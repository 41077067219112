<div appMain id="app">
  <div class="header-top">
    <div class="header-top-content">
      <a class="check-mail-btn agent-login" href="https://book.laoskyway.com/Sitelogin.aspx" target="_blank">
        <button [ngClass]="{lao: language_=='la'}" class="btn-agent"
          mat-button style="color: black;">
          <img src="assets/svg/officer.svg" width="30"> {{language_data.agent_login}}</button></a>
      <a class="check-mail-btn" href="https://mail.laoskyway.com/" target="_blank">
        <button mat-button [ngClass]="{lao: language_=='la'}" class="btn-checkmail"
          style="color: black;">
          <img src="assets/svg/email.svg" width="30"> {{language_data.check_mail}}</button></a>
      <button [ngClass]="{lao: language_=='la'}" class="btn-language" mat-button [matMenuTriggerFor]="beforeMenu">
        <span *ngIf="isEnglish"><img src="assets/svg/eng.svg" width="30"> {{language_data.english}}</span>
        <span *ngIf="!isEnglish"><img src="assets/svg/laos.svg" width="30"> {{language_data.lao}}</span>
      </button>
      <mat-menu #beforeMenu="matMenu" xPosition="before">
        <button [ngClass]="{lao: language_=='la'}" class="btn-language-menu" mat-menu-item *ngIf="isEnglish" (click)="change_language('la')">
          <img src="assets/svg/laos.svg" width="30"> {{language_data.lao}}
        </button>
        <button [ngClass]="{lao: language_=='la'}" class="btn-language-menu" mat-menu-item *ngIf="!isEnglish" (click)="change_language('eng')">
          <img src="assets/svg/eng.svg" width="30"> {{language_data.english}}
        </button>
      </mat-menu>
    </div>
  </div>
  <div class="header-bottom">
    <div class="content-border">

      <!--Navbar-->
      <mdb-navbar SideClass="navbar navbar-expand-lg navbar-dark header-bottom-content" [containerInside]="false">


        <!-- Navbar brand -->
        <mdb-navbar-brand><a class="navbar-brand" href="https://laoskyway.com/">
            <div class="header-logo">
              <img src="assets/imgs/lsw-logo.png" width="200" class="home-logo">
            </div>
          </a></mdb-navbar-brand>

        <!-- Collapsible content -->
        <links>

          <!-- Links -->
          <ul class="navbar-nav ml-auto" [ngClass]="{lao: language_=='la'}">
            <li class="nav-item dropdown" dropdown>
              <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light"
                mdbWavesEffect>
                {{language_data.services}}<span class="caret"></span>
              </a>
              <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
                <!-- <a class="dropdown-item waves-light" mdbWavesEffect href="/on-construction">{{language_data.scenic_flight}}</a> -->
                <a class="dropdown-item waves-light" mdbWavesEffect href="/charter_filght">{{language_data.charter_flight}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/airline">{{language_data.airline_route_map}}</a>
                <!-- <a class="dropdown-item waves-light" mdbWavesEffect href="https://www.laoskywayhotel.com/XKH/"
                  target="_blank">{{language_data.laoskyway_xiengkhuang_hotel}}</a> -->
                <!-- <a class="dropdown-item waves-light" mdbWavesEffect href="http://laoskywayhotel.com/"
                  target="_blank">{{language_data.laoskyway_vientiane_hotel}}</a> -->
                <!-- <a class="dropdown-item waves-light" mdbWavesEffect href="https://laoskywaytravel.com/"
                  target="_blank">{{language_data.laoskyway_travel}}</a>-->
                <a class="dropdown-item waves-light" mdbWavesEffect href="/cargo_check">{{language_data.cargo_tracking}}</a>
              </div>
            </li>
            <li class="nav-item dropdown" dropdown>
              <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light"
                mdbWavesEffect>
                {{language_data.sky_card}}<span class="caret"></span>
              </a>
              <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
                <a class="dropdown-item waves-light" mdbWavesEffect href="#">{{language_data.sky_card_partner}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect
                  href="https://book.laoskyway.com/UserProfileRegistration.aspx?lang=en&sesid=" target="_blank">{{language_data.sky_card_register}}</a>
              </div>
            </li>
            <li class="nav-item dropdown" dropdown>
              <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light"
                mdbWavesEffect>
                {{language_data.destinations}}<span class="caret"></span>
              </a>
              <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
                <!--<a class="dropdown-item waves-light" mdbWavesEffect href="/on-construction">{{language_data.vientiane}}</a>-->
                <a class="dropdown-item waves-light" mdbWavesEffect href="/vte_des">{{language_data.vientiane}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/luangprabang_des">{{language_data.luangprabang}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/bokeo_des">{{language_data.borkeo}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/oudomxay_des">{{language_data.oudomxay}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/phongsaly_des">{{language_data.phongsaly}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/luangnamtha_des">{{language_data.luangnamtha}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/nue_des">{{language_data.houphan}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/parkse_des">{{language_data.parkse}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/zby_des">{{language_data.xayyabuly}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/zvk_des">{{language_data.savannaket}}</a>
              </div>
            </li>
            <li class="nav-item">
              <a class="nav-link waves-light" mdbWavesEffect href="/flight_schedule">
                {{language_data.flight_schedule}}
              </a>
            </li>
            <li class="nav-item dropdown" dropdown>
              <a dropdownToggle mdbWavesEffect type="button" class="nav-link dropdown-toggle waves-light"
                mdbWavesEffect>
                {{language_data.about_us}}<span class="caret"></span>
              </a>
              <div *dropdownMenu class="dropdown-menu dropdown-menu-right dropdown dropdown-primary" role="menu">
                <a class="dropdown-item waves-light" mdbWavesEffect href="/about-us-laoskyway">{{language_data.laoskyway}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/org_chart">{{language_data.organization_chart}}</a>
                <!-- <a class="dropdown-item waves-light" mdbWavesEffect href="/terms&conditions">{{language_data.general_terms_and_conditions}}</a> -->
                <a class="dropdown-item waves-light" mdbWavesEffect href="/about-us-FleetInfo">{{language_data.fleet_informations}}</a>
                <!-- <a class="dropdown-item waves-light" mdbWavesEffect href="/on-construction">{{language_data.videos}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/on-construction">{{language_data.news}}</a> -->
                <a class="dropdown-item waves-light" mdbWavesEffect href="/contact_us">{{language_data.contact_us}}</a>
                <a class="dropdown-item waves-light" mdbWavesEffect href="/activities">{{language_data.acivities_and_news}}</a>
              </div>
            </li>
            <li class="nav-item" [ngClass]="{lao: language_=='la'}">
              <a class="nav-link waves-light" mdbWavesEffect href="/feedback">
                {{language_data.feed_back}}
              </a>
            </li>
            <li class="nav-item" [ngClass]="{lao: language_=='la'}">
              <a class="nav-link waves-light" mdbWavesEffect href="/careers">
                {{language_data.careers}}
              </a>
            </li>

          </ul>
          <!-- Links -->
        </links>
        <!-- Collapsible content -->

      </mdb-navbar>
      <!--/.Navbar-->
    </div>
  </div>

  <router-outlet></router-outlet>
  <app-footer class="main-footer"></app-footer>
</div>