<div class="terms-condition-section" style="margin-bottom:130px;">
    <div class="top-img">
        <img class="img" src="assets/imgs/top_img.png">
        <div class="top-title">General Term and Condition</div>
    </div>
    <div class="term-condition-body">
        <div class="h5">Check-in term and condition</div>
        <p><b>a)</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Check-in counters close 30 minutes prior to the Scheduled time of departure. Passengers failing to check-in on time will not be accepted for travel and will forfeit their flight & their ticket. Passenger will be marked as NO-SHOW and will be liable for  a BO-SHOW POLICY.</p>
        <p><b>b)</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;It is the passenger&#39;s responsibility to ensure that they have photo identify card or passport to prove their identity while checking.</p>
        <p><b>c)</b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Laoskyway&#39;s Check-In staff will have the right to deny a passenger to be checked in if the proof of identity is not presented while checking in.</p>
        <div class="h5">Baggage term and condition</div>
        <div class="h6"> <img src="assets/svg/play_button.svg" width="15">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Checked Baggage</div>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This Policy is reinstated effective on flight number: <span style="color:red;"> LK 206, LK 027, LK 208, LK 209, LK 212, LK 213, LK 216, LK 217, LK 220, LK 221, LK 264</span> and <span style="color:red;">LK 265</span>. <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Baggage Allowance of less than <span style="color:red;">20kg</span> is included per Passenger(Adult or Child) excluding infant <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;This policy is reinstated effective on flight number: <span style="color:red;">LK 200, LK 201, LK 202</span> and <span style="color:red;">LK 203</span>. <br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Baggage Allowance of less than <span style="color:red;">10kg</span> is included per Passenger(Adult or Child) excluding infant
        </p>
        <div class="h6"> <img src="assets/svg/play_button.svg" width="15">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cabin Baggage</div>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All guest (excluding infants) are allowed to carry on  board two (2) pieces of cabin baggage comprising of either: One (1) cabin baggage or one (1) laptop or one (1) handbag or one (1) small bag. <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For Passenger on Flight number:<span style="color:red;"> LK 206, LK 027, LK 208, LK 209, LK 212, LK 213, LK 216, LK 217, LK 220, LK 221, LK 264</span> and <span style="color:red;">LK 265</span> <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>The total weight of both pieces must not exceed <span style="color:red;">6kg</span></i></b> <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;For Passenger on Flight number:<span style="color:red;"> LK 200, LK 201, LK 202</span> and <span style="color:red;">LK 203</span>. <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <b><i>The total weight of both pieces must not exceed <span style="color:red;">5kg</span></i></b>
        </p>
        <div class="h6"> <img src="assets/svg/play_button.svg" width="15">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Infant Baggage</div>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Free Baggage Allowance per Passenger infant: <br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Checked baggage and hand baggage total weight of both baggage must not exceed <span style="color:red;">7kg</span>
        </p>
        <div class="h5">Excess baggage charge condition:</div>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Excess baggage will be charged 12,000 LAK per Kilogram and will not be guaranteed to be transported on the same flight. This will depend on the load factor of that particular flight.
        </p>
        <div class="h5">Valuable and Fragile Goods:</div>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Passengers are strongly advised not to check-in such items as baggage. If they are checked in as baggage, passengers agree they send for carriage of such items at their own risk. Such items include money, jewelry, precious metals, silver-ware, electronic devices, computers, cameras, video equipment, negotiable papers, securities or other valuables, passports and other identification documents and the like.
        </p>
        <div class="h5">Items Forbidden to be Carried:</div>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dangerous Goods, or prohibited items on aircraft, are items that may endanger the safety of an aircraft or persons on board the aircraft. If you are not sure whether the item you wish to pick in your luggage is dangerous or for details on how to safety transport your dangerous goods, please contact Laoskyway Call Center Tel: 1441 or email: <a href="mailto:service@laoskyway.com" target="_blank">service@laoskyway.com</a>
        </p>
        <p><img src="assets/svg/shape.svg" width="10">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case of any damage to checked baggage, Lao Skyway will compensate only actual damages BUT in any case not more than 120,000 LAK or equivalent.</p>
        <p><img src="assets/svg/shape.svg" width="10">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case of lost checked baggage, Lao Skyway will compensate only actual costs BUT not more than 80,000 LAK per Kilogram of checked baggage and in any case not more than 480,000 LAK in total.</p>
        <p><img src="assets/svg/shape.svg" width="10">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;In case the baggage delivery is deleyed, we may consider compensating not more than 120,000 LAK per person; provided that, the passenger is required to fill in the  “Claim Form”, whick is available at our ticketing counter at the airport and submit it to our staff for further proceesing.</p>
        <p><img src="assets/svg/shape.svg" width="10">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Passengers shall be responsible for their own carry-on baggage(s).</p>
        <p><img src="assets/svg/shape.svg" width="10">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;All other terms and conditions shall be accordance with Lao Skyway Terms and Conditions which the passengers shall agree and acknowledge at checking-in and before boarding the aircraft.</p>
        <div class="h5">Booking term and condition</div>
        <p> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Advance reservations can be made a minimum of 48 hours and a maximum of 11 months prior to departure.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A maximum of 7 (seven) passengers in the same booking.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;To ensure that you get the quoted fare the reservation should be made immediately thereafter.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payment must be made and tickets collected within 01 hours of making a reservation.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Your ticket is not transferable.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Flight confirmation will be automatically sent to your email address.</p>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cancellation of Onward Reservations. Please be advised that if you do not show up for any flight without advising us in advance, we may cancel your return or onward reservations. However, if you do advise us in advance, we will not cancel your subsequent flight.</p>
        <div class="h5">Booking term and condition</div>
        <div class="payment-term">
            <div class="payment-term-part1">
                <p><b>Credit card</b><br>- Visa, MasterCard, JCB and CUP</p>
                <p><b>Debit card</b><br>- Visa, MasterCard worldwide journey, JCB and CUP</p>
                <p><b>Bank Direct Debit (Lao Only)</b><br>- BCEL, JCB, LDB ST AND BFL</p>
            </div>
            <div class="payment-term-part2">
                <p><b>Over The Counter (Lao only)</b><br>- Laoskyway ticket office</p>
                <p><b>Bank ATM (Lao only)</b><br>- BCEL, JDB, LDB ST AND BFL</p>
                <p><b>QR Code (Lao only)</b><br>- BECL QR CODE</p>
            </div>
        </div>
        <div class="h5">Right to Refuse Carriage</div>
        <u>Credit Card verification</u>
        <p>&nbsp;&nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;When purchasing online with a card, some issuingbanks may not authorize payment. Please try using a different card, or contact the card issuer.</p>
        <div class="h5">Cancellation / Amendment policy:</div>
        <div class="cnl-policy">
            <div class="cnl-policy-title">
                <p> <b>Laoskyway Flexi</b>, change can be made through Call Center Tel. 1441 and Laoskyway Ticket Counter or e-mail: service@laoskyway.com subject to the following conditions:</p>
            </div>
            <div>i.</div>
            <div>Flight and route change will be subject to change (50,000 LAK per passenger per time)</div>
            <div>ii.</div>
            <div>Passenger Name Change is allowed. Change will be subject to change fee (50,000 LAK per passenger per booking).</div>
            <div>iii.</div>
            <div>Refund subject to charge 50,000 LAK.</div>
            <div>iv.</div>
            <div>NO SHOW status allows to be changed. All changes must be made within no show date. Change will be subject to change fee (50,000 LAK per passenger per booking).</div>
        </div>
        <div class="cnl-policy">
            <div class="cnl-policy-title">
                <p> <b>Laoskyway BASE</b>, change can be made through Call Center Tel. 1441 and Laoskyway Ticket Counter or e-mail: service@laoskyway.com subject to the following conditions:</p>
            </div>
            <div>i.</div>
            <div>Flight and route change will be subject to change (100,000 LAK per passenger per time)</div>
            <div>ii.</div>
            <div>Passenger Name Change is allowed. Change will be subject to change fee (100,000 LAK per passenger per booking).</div>
            <div>iii.</div>
            <div>Refund subject to charge 100,000 LAK.</div>
            <div>iv.</div>
            <div>NO SHOW status allows to be changed. All changes must be made within no show date. Change will be subject to change fee (50,000 LAK per passenger per booking).</div>
        </div>
        <div class="cnl-policy">
            <div class="cnl-policy-title">
                <p> <b>Laoskyway ROGER</b>, change can be made through Call Center Tel. 1441 and Laoskyway Ticket Counter or e-mail: service@laoskyway.com subject to the following conditions:</p>
            </div>
            <div>i.</div>
            <div>Flight and route change will be subject to change (150,000 LAK per passenger per time)</div>
            <div>ii.</div>
            <div>Passenger Name Change is allowed. Change will be subject to change fee (150,000 LAK per passenger per booking).</div>
            <div>iii.</div>
            <div>Refund subject to charge 150,000 LAK.</div>
            <div>iv.</div>
            <div>NO SHOW status allows to be changed. All changes must be made within no show date. Change will be subject to change fee (50,000 LAK per passenger per booking).</div>
        </div>
        <div class="cnl-policy">
            <div class="cnl-policy-title">
                <p> <b>Laoskyway LOVE</b>, change can be made through Call Center Tel. 1441 and Laoskyway Ticket Counter or e-mail: service@laoskyway.com subject to the following conditions:</p>
            </div>
            <div>i.</div>
            <div>Flight and route change will be subject to change (200,000 LAK per passenger per time)</div>
            <div>ii.</div>
            <div>Passenger Name Change is allowed. Change will be subject to change fee (200,000 LAK per passenger per booking).</div>
            <div>iii.</div>
            <div>Refund subject to charge 200,000 LAK.</div>
            <div>iv.</div>
            <div>NO SHOW status allows to be changed. All changes must be made within no show date. Change will be subject to change fee (50,000 LAK per passenger per booking).</div>
        </div>
        <div class="cnl-policy">
            <div class="cnl-policy-title">
                <p> <b>Laoskyway PRO</b>, all change and refund are not permitted</p>
            </div>
        </div>
        <div class="h5">Refund term and condition</div>
        <div class="refund-dondition">
            <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Refunds can be made by contacting Laoskyway office. Refunds will be credited back to the credit card or Visa/MasterCard 
                Debit card that was used for payment and a refund fee may be applied. However, please be aware that refund processing time of debit card payments may take longer than that of credit cards, 
                as it depends on the process of the debit card issuing bank.</p>
            <p>-&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Laoskyway may refuse refund application is made later than 30 days after expiry of Tickets for refundable Tickets. Laoskyway endeavors to maintain 
                its flight operations in accordance to the published schedule, however the actual departure or arrival time of each flight may be changed or delayed owing to circumstances beyond the control 
                of Laoskyway. In such cases, Laoskyway shall take no responsibilities from the changes, delays and cancellation to the flight.</p>
        </div>
    </div>
    <div class="bottom-img">
        <img class="img" src="assets/imgs/bottom_img.png">
    </div>
</div>
