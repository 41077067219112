<div class="container">
    <div class="flight-search-widget" 
        data-prop-api-setting="soarapi" 
        data-prop-default-currency="USD"
        data-prop-lang="en" 
        data-prop-endpoint="laoskyway" 
        data-prop-environment="prod"
        data-prop-ngi="on" 
        data-prop-show-cabin-class="off" 
        data-prop-show-currencies="on"
        data-prop-show-available-dates="on" 
        data-prop-use-ptc="on" 
        data-prop-ptc-types="ADT:CHD:INF"
        data-prop-ptc-default="ADT|1:CHD|0:INF|0"
        data-prop-date-format="MM-DD-YYYY" 
        data-prop-start-on-monday="on"
        data-prop-ngi-url="https://m.laoskyway.com"
        data-prop-horizontal-breakpoint="900" 
        data-prop-list-position="right" 
        data-prop-base-color="#FF0000"
        data-prop-secondary-color="#21409a">
        <div class="loader">LOADING...</div>
    </div>
</div>

<!-- <div class="container">
    <div class="flight-search-widget" 
        data-prop-lang="cus" 
        data-prop-api-setting="soarapi" 
        data-prop-default-currency="USD"
        data-prop-endpoint="laoskyway" 
        data-prop-environment="prod"
        data-prop-environment="maint"
        data-prop-ngi="mobile" 
        data-prop-show-cabin-class="off" 
        data-prop-show-currencies="on"
        data-prop-show-available-dates="on" 
        data-prop-use-ptc="on" 
        data-prop-ptc-types="ADT:CHD:INF"
        data-prop-date-format="MM-DD-YYYY" 
        data-prop-start-on-monday="on"
        data-prop-ngi-url="https://m.laoskyway.com" 
        data-prop-ibe-url="https://book.laoskyway.com/"
        data-prop-horizontal-breakpoint="900" 
        data-prop-list-position="right" 
        data-prop-base-color="#FF0000"
        data-prop-secondary-color="#21409a">
        <div class="loader">LOADING...</div>
    </div>
</div> -->