import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-boss-view',
  templateUrl: './boss-view.component.html',
  styleUrls: ['./boss-view.component.scss']
})
export class BossViewComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BossViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data:DialogData) { }

  ngOnInit(): void {
  }

}
export interface DialogData{
  img: string,
  name: string
}
