<section id=des-nue style="margin-bottom:130px;">
    <div class="header">HOUAPHANH PROVINCE (Samneau)</div>
    <div class="body">
        <b>Houaphanh Province: </b>is the birthplace of the Lao People’s Democratic Republic. However, it is one of the least visited provinces in the country and offers an authentic and varied experience for visitors. Enjoy a pristine natural environment of mountains, rivers, waterfalls and forests, visit the caves at Vieng Xay for a unique insight into the history of the country. Discover the mysterious Standing Stones of Hintang Archaeological Park, a collection of upright, rough stones about 2 meters high, some of which have tunnel-like caverns beneath them, which date back more than 2000 years.

        Houaphanh has many unique and beautiful natural attractions. The provincial capital, Xam Neua, is a quiet and rustic city on the Xam River. Here you will enjoy markets trading produce from China and Vietnam and a variety of handicrafts produced by the Hmong, Tai Dam, Tai Daeng and Tai Lue ethnic groups. Also of interest in Xam Neua is Wat Phoxainaram, home to an impressive bronze Buddha possibly dating back to 1565 and an interesting series of murals depicting the life of Buddha and scenes from Lao History.
        
        Vieng Xay, located northeast of the capital, boasts over 100 limestone caves, 10 of which have immense national and historical significance as the hidden headquarters of the Neo Lao Hak Sad from 1961-1975. In the southeast of Houaphanh is the Nam Xam National Protected Area (NPA), which provides habitat for wild elephants, various gibbons, gaur, banteng, tiger, clouded leopard, Asiatic black bear and Malayan sun bear. South of Xam Neua is Tat Saloei, a beautiful waterfall best viewed after the rainy season. Nam Et-Phou Loei is the largest NPA in Laos situated in the northwest of Houaphanh. It is best known for harboring one of the most important tiger populations remaining in Indochina. Its outstanding biodiversity includes over 30 species of large mammals and 300 species of birds.
        
        The best Tai Daeng woven textiles are produced in Houaphanh, At local markets you will find an interesting variety of cutlery, jewellery and other items fashioned from Cluster Bomb Units and war scrap that remains scattered throughout the countryside. Houaphanh Province boasts the usual variety of Lao noodles, fragrant sticky rice and local dishes. The most notable are a vegetable salad flavored with sesame called “soup phak” and chili paste known as “jaeow pa baum”.
    </div>
    <div class="img-slide">
        <mdb-carousel [animation]="'slide'" [ngSwitch]="carouselDisplayMode" [isControls]="true"
            class="carousel-multi-item multi-animation" [type]="'carousel-multi-item'">
            <ng-container *ngSwitchCase="'single'">
                <mdb-carousel-item *ngFor="let card of cards">
                    <mdb-card class="my-1">
                        <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                    </mdb-card>
                </mdb-carousel-item>
            </ng-container>

            <ng-container *ngSwitchCase="'multiple'">
                <mdb-carousel-item *ngFor="let item of slides; let i = index">
                    <div class="col-md-4" *ngFor="let card of item; let cardIndex = index">
                        <mdb-card class="my-1">
                            <mdb-card-img [src]="card.img" alt="Card image cap"></mdb-card-img>
                        </mdb-card>
                    </div>
                </mdb-carousel-item>
            </ng-container>
        </mdb-carousel>
    </div>

    <div class="map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d957988.0933651383!2d103.48891328087858!3d20.294430502865918!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313190c0e0de9649%3A0xd826dd5dfbe925e8!2sHouaphanh%20Province!5e0!3m2!1sen!2sla!4v1672729070223!5m2!1sen!2sla" 
        width="100%" height="400" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0">
        </iframe>
    </div>
</section>