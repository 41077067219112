<div class="popup" [hidden]="hide_popup">
    <app-main-popup (closed_popup)="close_popup($event)"></app-main-popup>
</div>
<div style="position:relative;">
    <div class="head"></div>
    <div class="slide-img">
        <!-- booking form -->
        <div class="book-form">
            <app-book-form class="form-content"></app-book-form>
        </div>
        <!--end booking form -->
        <div *ngIf="slide_num==1">
            <img src="{{slide_data[0].img_path}}" width="100%">
        </div>
        
        <mdb-carousel class="carousel slide" [animation]="'slide'" *ngIf="slide_num>=2">
            <mdb-carousel-item *ngFor="let item of slide_data">
                <img class="d-block w-100" src="{{item.img_path}}" alt="Second slide">
            </mdb-carousel-item>
        </mdb-carousel>
    </div>
</div>

<div class="home-content">
    
    <!--
    <div class="service-content" *ngIf="show_auc">
        <div class="service-content-header">
            <div class="service-content-header-label" [ngClass]="{lao: language_=='la'}">
               AUCTION INVITATION
            </div>
            <div class="content-header-line-01"></div>
        </div>
        <div class="auction">
            <div class="auction-body">
                <div class="auc-img-frame">
                    <img src="assets/imgs/auc_inv.jpg" class="auc-img">
                </div>
                <div class="auc-act">
                    <a href="assets/files/auc_inv.pdf" target="_blank">Read More...</a>
                </div>
            </div>
            <div class="auction-body">
                <div class="auc-img-frame">
                    <img src="assets/imgs/auc_reg.jpg" class="auc-img">
                </div>
                <div class="auc-act">
                    <a href="assets/files/auc_reg.pdf" target="_blank">Read More...</a>
                </div>
            </div>
        </div>

    </div>
    -->


    <div class="service-content">
        <!--
        <div class="service-content-header" style="text-align: left;">
            <div class="service-content-header-label" [ngClass]="{lao: language_=='la'}">
               {{language_data.services}}
            </div>
        </div>-->
        <div class="service-content-icons">
            <div class="service">
                <a href="/ticket-checking" id="ticket-checking" class="">
                    <img src="assets/imgs/Service001.png" class="img">
                </a>
                <div style="padding:15px 0px 15px 0px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 20px;">{{language_data.check_ticket}}</span></div>
                <div style="padding:5px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 13px;">{{language_data.check_ticket_detail}}</span></div>
                    
            </div>
            <div class="service">
                <a href="/airline" id="airline" class="">
                    <img src="assets/imgs/Service002.png" class="img">
                </a>
                <div style="padding:15px 0px 15px 0px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 20px;">{{language_data.airline}}</span></div>
                <div style="padding:5px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 13px;">{{language_data.airline_detail}}</span></div>
                    
            </div>
            <div class="service">
                <a href="/charter_filght" id="charter_filght">
                    <img src="assets/imgs/Service003.png" class="img">
                </a>
                <div style="padding:15px 0px 15px 0px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 20px;">{{language_data.charter_flight}}</span></div>
                <div style="padding:5px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 13px;">{{language_data.charter_detail}}</span></div>
            </div>
            <div class="service">
                <a href="/private-jet" id="private-jet">
                    <img src="assets/imgs/Service004.png" class="img">
                </a>
                <div style="padding:15px 0px 15px 0px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 20px;">{{language_data.privatejet}}</span></div>
                <div style="padding:5px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 13px;">{{language_data.privatjet_detail}}</span></div>
                 
            </div>

            <div class="service">
                <a href="/cargo-info" id="cargo-info">
                    <img src="assets/imgs/Service005.png" class="img">
                </a>
                <div style="padding:15px 0px 15px 0px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 20px;">{{language_data.cargo}}</span></div>
                <div style="padding:5px;"><span [ngClass]="{lao: language_=='la'}" style="font-size: 13px;">{{language_data.cargo_detail}}</span></div>
                 
            </div> 
        </div>
    </div>
    <!--End of service content-->


    <div class="app-store-section">
        <div class="app-store-section-inner">
            <a href="https://play.google.com/store/apps/details?id=com.app.laoskyway.dev" target="_blank">
                 <img class="imgapp" src="assets/footer_ico/app-lsw.png">
            </a>
        </div>
        <div class="app-store-section-inner">
            <img class="imgmap" src="assets/footer_ico/MAP2.png">
        </div>
    </div>

    <!-- <div class="alert alert-danger alert-dismissible fade {{alert_str}} covid_19_alert" role="alert">
        <a class="alert-url" href="{{alert_img_url}}" target="_blank"> <strong>{{alert_text_b}}</strong> {{alert_text_n}}</a> 
        <mat-icon (click)="alert_str = 'hide'" style="cursor: pointer;">close</mat-icon>
    </div> -->
</div>