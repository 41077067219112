import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-private-jet',
  templateUrl: './private-jet.component.html',
  styleUrls: ['./private-jet.component.scss']
})
export class PrivateJetComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
