import { BrowserModule } from '@angular/platform-browser';
import { NgModule,NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { HomeComponent } from './components/home/home.component';
import { BookFormComponent } from './components/book-form/book-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material-module';
import { FooterComponent } from './components/footer/footer.component';
import { LaoskywayComponent } from './components/laoskyway/laoskyway.component';
import { OnConstructionComponent } from './components/on-construction/on-construction.component';
import { FeedbackComponent } from './components/feedback/feedback.component';
import { WebpageAdminComponent } from './components/webpage-admin/webpage-admin.component';
import { MainDirective } from './main.directive';
import { AdminLoginComponent } from './components/admin-login/admin-login.component';
import { AdminSlideComponent } from './components/admin-slide/admin-slide.component';
import { AdminJobvacancyComponent } from './components/admin-jobvacancy/admin-jobvacancy.component';
import { HttpClientModule } from '@angular/common/http';
import { LoadingComponent } from './components/loading/loading.component';
import { MainPopupComponent } from './components/main-popup/main-popup.component';
import { FleetInfoComponent } from './components/fleet-info/fleet-info.component';
import { CargoTrackingComponent } from './components/cargo-tracking/cargo-tracking.component';
import { RouteMapComponent } from './components/route-map/route-map.component';
import { OrgChartComponent } from './components/org-chart/org-chart.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

import { AgmCoreModule } from '@agm/core';
import { LuangprabangDesComponent } from './components/luangprabang-des/luangprabang-des.component';
import { BokeoDesComponent } from './components/bokeo-des/bokeo-des.component';
import { OudomxayDesComponent } from './components/oudomxay-des/oudomxay-des.component';
import { LuangnamthaDesComponent } from './components/luangnamtha-des/luangnamtha-des.component';
import { FlightScheduleComponent } from './components/flight-schedule/flight-schedule.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { AdminSetupComponent } from './components/admin-setup/admin-setup.component';
import { AdminNewuserComponent } from './dialogs/admin-newuser/admin-newuser.component';
import { EventEmitterService } from './services/event-emitter.service';
import { AdminPopupSetupComponent } from './components/admin-popup-setup/admin-popup-setup.component';
import { AirlineComponent } from './components/airline/airline.component';
import { ActivitiesComponent } from './components/activities/activities.component';
import { TermsConditionsComponent } from './components/terms-conditions/terms-conditions.component';
import { CharterFilghtComponent } from './components/charter-filght/charter-filght.component';
import { AdminNewsActivitiesComponent } from './components/admin-news-activities/admin-news-activities.component';
import { NewsActivitiesDialogComponent } from './dialogs/news-activities-dialog/news-activities-dialog.component';
import { BossViewComponent } from './dialogs/boss-view/boss-view.component';
import { CareerComponent } from './components/career/career.component';
import { PhongsalyDesComponent } from './components/phongsaly-des/phongsaly-des.component';
import { ParkseDesComponent } from './components/parkse-des/parkse-des.component';
import { NueDesComponent } from './components/nue-des/nue-des.component';
import { ZbyDesComponent } from './components/zby-des/zby-des.component';
import { VteDesComponent } from './components/vte-des/vte-des.component';
import { ZvkDesComponent } from './components/zvk-des/zvk-des.component';
import { TicketCheckingComponent } from './components/ticket-checking/ticket-checking.component';
import { PrivateJetComponent } from './components/private-jet/private-jet.component';
import { CargoInfoComponent } from './components/cargo-info/cargo-info.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BookFormComponent,
    FooterComponent,
    LaoskywayComponent,
    OnConstructionComponent,
    FeedbackComponent,
    WebpageAdminComponent,
    MainDirective,
    AdminLoginComponent,
    AdminSlideComponent,
    AdminJobvacancyComponent,
    LoadingComponent,
    MainPopupComponent,
    FleetInfoComponent,
    CargoTrackingComponent,
    RouteMapComponent,
    OrgChartComponent,
    ContactUsComponent,
    LuangprabangDesComponent,
    BokeoDesComponent,
    OudomxayDesComponent,
    LuangnamthaDesComponent,
    FlightScheduleComponent,
    ConfirmDialogComponent,
    AdminSetupComponent,
    AdminNewuserComponent,
    AdminPopupSetupComponent,
    AirlineComponent,
    ActivitiesComponent,
    TermsConditionsComponent,
    CharterFilghtComponent,
    AdminNewsActivitiesComponent,
    NewsActivitiesDialogComponent,
    BossViewComponent,
    CareerComponent,
    PhongsalyDesComponent,
    ParkseDesComponent,
    NueDesComponent,
    ZbyDesComponent,
    VteDesComponent,
    ZvkDesComponent,
    TicketCheckingComponent,
    PrivateJetComponent,
    CargoInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MDBBootstrapModule.forRoot(),
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyABmRtRVrqdxvZ30IQkt6_hJ4mGoQAhX9E'
    })
  ],
  schemas: [ NO_ERRORS_SCHEMA ],
  providers: [EventEmitterService],
  bootstrap: [AppComponent]
})
export class AppModule { }
